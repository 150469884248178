import "./contact-form.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

interface PropsForm extends PropsWithChildren<any> {
  handleSubmit: any;
  submitting: boolean;
  success: boolean;
  fail: boolean;
}

export const Contacto = (props: PropsForm) => (
  <form
    name="contacto"
    method="post"
    netlify-honeypot="bot-field"
    data-netlify="true"
    onSubmit={props.handleSubmit}
    className="m-form m-form--modal"
  >
    <input type="hidden" name="form-name" value="contacto" />
    <label hidden> Nombre </label>
    <input type="text" name="nombre" placeholder="Nombre" required />
    <p className="hidden">
      <input type="text" name="bot-field" placeholder="Nombre" />
    </p>

    <label hidden>Email </label>
    <input type="email" name="email" placeholder="Email" required />
    <textarea placeholder="Mensaje" name="mensaje" required />
    <div className={"a-private"}>
      <label htmlFor="politica">
        {" "}
        Acepto la{" "}
        <a
          href="/legal/politica-de-privacidad/"
          target="_blank"
          rel="nofollow noopener noreferrer"
          className={"a-policy"}
        >
          política de privacidad
        </a>{" "}
      </label>
      <input
        type="checkbox"
        name="politica"
        id="politica"
        value="acepta-politica"
        required
      />
    </div>

    <div className="center">
      {!props.success && (
        <button type="submit" className={"a-button"}>
          Enviar {props.submitting && <span className="a-spinner" />}
        </button>
      )}
    </div>
    {props.fail && (
      <span className="a-fail">
        Ha ocurrido un <b>error</b>, puedes ponerte en contacto a través de{" "}
        <a
          href="mailto:info@almayser.es"
          target={"_blank"}
          rel={"noopener noreferrer nofollow"}
        >
          info@almayser.es
        </a>{" "}
        o vía{" "}
        <a
          href="https://wa.me/+34634464316"
          target={"_blank"}
          rel={"noopener noreferrer nofollow"}
        >
          {" "}
          whatsapp
        </a>
      </span>
    )}
    {props.success && (
      <span className="a-success">Formulario enviado con éxito</span>
    )}
  </form>
);
