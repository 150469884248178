import "./post-list.scss";

import { graphql, Link, useStaticQuery } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";

// import { PostSearch } from "../../../contexts/blog/application/SearchAll/PostSearch";
// import { Post } from "../../../contexts/blog/domain/post";
// import { PostRepository } from "../../../contexts/blog/domain/PostRepository";
// import { SanityPostsRepository } from "../../../contexts/blog/infrastructure/sanityPostsRepository";

const PostList: React.FC<{ posts: any[] }> = (props: { posts: any[] }) => {
  const posts_render = [];
  props.posts.forEach((post, index) => {
    posts_render.push(
      <div className="m-single-post" key={index}>
        <Link to={`/${post.slug.current}/`} title={post.title}>
          <SanityImage
            {...post.mainImage}
            width={350}
            height={350}
            className="a-single-post__image is-sepia"
            alt={post.title}
          />
        </Link>
        <Link
          to={`/${post.slug}/`}
          title={post.title}
          className={"a-single-post__title"}
        >
          {post.title}
        </Link>
      </div>
    );
  });

  return (
    <div className="o-posts">
      <div className="m-post-list grid-x2 grid-x2--no-gap grid-x2--break-pro">
        {posts_render}
      </div>
    </div>
  );
};

export default PostList;
