import "./pagination.scss";

import { Link } from "gatsby";
import * as React from "react";
import { PropsWithChildren } from "react";

export interface PropsPagination extends PropsWithChildren<any> {
  nextPagePath: string;
  numberOfPages: number;
  pageNumber: number;
  previousPagePath: string;
  categorySlug?: string;
}

const Pagination: React.FC<PropsPagination> = (props: PropsPagination) => {
  const has_fivePagesOrMore: boolean = props.numberOfPages >= 5;
  const numbersPagination_render: any[] = [];
  let numberStart: number =
    has_fivePagesOrMore && props.pageNumber > 2 ? props.pageNumber - 1 : 1;
  let numberStop: number = has_fivePagesOrMore
    ? props.pageNumber + 1
    : props.numberOfPages;
  let lastNumberPrint: number = 0;
  if (numberStop < props.numberOfPages && numberStop < 3) {
    numberStop = 3;
  }
  if (has_fivePagesOrMore && numberStart > props.numberOfPages - 2) {
    numberStart = props.numberOfPages - 2;
  }
  for (let numberPage = numberStart; numberPage <= numberStop; numberPage++) {
    lastNumberPrint = numberPage;
    if (numberPage === 1) {
      numbersPagination_render.push(
        <Link
          className={`a-pagination__item ${
            props.pageNumber === numberPage ? "a-pagination__item--active" : ""
          } `}
          key={numberPage}
          to={`/comunidad/${
            props.categorySlug
              ? `${props.categorySlug}/#content-posts`
              : "#content-posts"
          }`}
        >
          {numberPage}
        </Link>
      );
      continue;
    }
    numbersPagination_render.push(
      <Link
        className={`a-pagination__item ${
          props.pageNumber === numberPage ? "a-pagination__item--active" : ""
        } `}
        key={numberPage}
        to={`/comunidad/${
          props.categorySlug ? `${props.categorySlug}/` : ""
        }${numberPage}#content-posts`}
      >
        {numberPage}
      </Link>
    );
    if (numberPage === props.numberOfPages) {
      break;
    }
  }
  return (
    <div className={"m-pagination"}>
      {props.pageNumber > 1 && (
        <>
          <Link
            title={"Anterior"}
            rel={"prev nofollow"}
            className={"a-pagination__item"}
            to={`${props.previousPagePath}#content-posts`}
          >
            &#60;&#60;
          </Link>
          {numberStart !== 1 && (
            <span className={"a-pagination__item"}>... </span>
          )}
        </>
      )}
      {numbersPagination_render}
      {has_fivePagesOrMore && (
        <>
          {/*{(props.numberOfPages !== props.pageNumber + 2 || !is_addEndDots) && <span>... </span>}*/}
          {lastNumberPrint !== props.numberOfPages - 1 && (
            <span className={"a-pagination__item"}>... </span>
          )}
          <Link
            className={`a-pagination__item ${
              props.pageNumber === props.numberOfPages
                ? "a-pagination__item--active"
                : ""
            } `}
            to={`/comunidad/${
              props.categorySlug ? `${props.categorySlug}/` : ""
            }${props.numberOfPages}#content-posts`}
          >
            {props.numberOfPages}
          </Link>
        </>
      )}
      {props.pageNumber !== props.numberOfPages && props.numberOfPages > 1 && (
        <Link
          title={"Siguiente"}
          rel={"next nofollow"}
          className={"a-pagination__item"}
          to={`${props.nextPagePath}#content-posts`}
        >
          &#62;&#62;
        </Link>
      )}
    </div>
  );
};

export default Pagination;
