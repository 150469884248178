import "./sidebar.scss";

import { graphql, Link, useStaticQuery } from "gatsby";
import * as React from "react";
import { useState } from "react";

import ModalForm from "../../../components/ModalForm";
import { CitaOnline } from "../../../components/ModalForm/ContactForm/CitaOnline";
import { Contacto } from "../../../components/ModalForm/ContactForm/Contacto";

const Sidebar: React.FC = () => {
  const data = useStaticQuery(graphql`
    query allCategories {
      allSanityCategory {
        nodes {
          slug {
            current
          }
          title
        }
      }
    }
  `);

  const categories_render: JSX.Element[] = [];
  data.allSanityCategory.nodes.forEach((category, index) => {
    categories_render.push(
      <li className={"a-categories-list__name"} key={index}>
        <Link to={`/comunidad/${category.slug.current}`}>{category.title}</Link>
      </li>
    );
  });

  const [has_modalCita, setHasModalCita] = useState(false);
  const [has_modalContacto, setHasModalContacto] = useState(false);

  return (
    <>
      {has_modalCita && (
        <ModalForm
          title="Consulta online"
          form={CitaOnline}
          is_active={setHasModalCita}
        />
      )}
      {has_modalContacto && (
        <ModalForm
          title="Contacta con nostras"
          form={Contacto}
          is_active={setHasModalContacto}
        />
      )}
      <aside className={"m-sidebar"}>
        <div className={"a-line-left"}></div>
        <div className={"m-sidebar__block"}>
          <p className={"a-sidebar__title"}>Te asesoramos</p>
          <p>
            Si estás elaborando tu preparado floral y tienes dudas sobre si la
            selección de la fórmula floral es la idónea, recuerda que puedes
            utilizar la <b>consulta escrita</b> para obtener una respuesta en
            menos de 24h.
          </p>
          <button
            className={"a-button a-button--full a-button--no-underline"}
            onClick={() => {
              setHasModalCita(true);
            }}
          >
            Consulta online
          </button>
        </div>
        <hr className={"a-separator"} />
        <div className={"m-sidebar__block"}>
          <p className={"a-sidebar__title"}>Categorías</p>
          <ul className={"m-categories-list"}>{categories_render}</ul>
        </div>
        <hr className={"a-separator"} />
        <div className={"m-sidebar__block"}>
          <p className={"a-sidebar__title"}>Unite al equipo Almayser</p>
          <p>
            Si eres profesional holístico y quieres trabajar con nosotras,
            envíanos un email con tus datos y estaremos encantadas de ponernos
            en contacto contigo.
          </p>
          <button
            className={"a-button a-button--full a-button--no-underline"}
            onClick={() => {
              setHasModalContacto(true);
            }}
          >
            Únete al equipo
          </button>
        </div>
        <hr className={"a-separator"} />
      </aside>
    </>
  );
};

export default Sidebar;
